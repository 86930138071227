<template>
  <div class="animate__animated animate__fadeIn mb-12">
    <div class="flex w-full h-96 bg-service mb-6 justify-center">
      <div
        class="
          z-0
          bg-gray-900 bg-opacity-50
          w-full
          flex
          items-center
          justify-center
        "
      ></div>
    </div>
    <div class="container mx-auto p-4">
      <div class="mb-6 text-xl">
        <a
          @click="changeLang()"
          href="javascript:void(0)"
          class="hover:underline"
        >
          {{ langText }}
        </a>
      </div>
      <h3 class="text-4xl">{{ $t("message.connect_with_us") }}</h3>
      <p class="mb-6">{{ $t("message.please_fill") }}</p>

      <div class="grid lg:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-6 gap-4">
        <div>
          <span class="required"> {{ $t("message.full_name") }} </span>
          <input
            type="text"
            v-model="input.fullName"
            class="w-full border p-2"
          />
        </div>
        <div>
          <span class="required">{{ $t("message.gender") }}</span>
          <v-select
            class="border"
            :options="genderOptions"
            label="name"
            v-model="input.gender"
            :reduce="(gender) => gender.id"
          ></v-select>
        </div>
        <div>
          {{ $t("message.age") }}
          <input type="number" v-model="input.age" class="w-full border p-2" />
        </div>
        <div>
          {{ $t("message.email") }}
          <input type="email" v-model="input.email" class="w-full border p-2" />
        </div>
        <div>
          {{ $t("message.mobile") }}
          <input type="text" v-model="input.mobile" class="w-full border p-2" />
        </div>
        <div>
          {{ $t("message.home_tel") }}
          <input
            type="text"
            v-model="input.officeHome"
            class="w-full border p-2"
          />
        </div>
        <div>
          {{ $t("message.preferred_lang") }}
          <v-select
            v-model="input.language"
            class="w-full"
            :reduce="(lang) => lang.id"
            :options="[
              { id: 'bm', name: 'Bahasa Malaysia' },
              { id: 'en', name: 'English' },
            ]"
            label="name"
          ></v-select>
        </div>
        <div>
          <span class="required">{{ $t("message.remarks_comments") }}</span>
          <textarea
            class="w-full border resize-none p-2"
            cols="30"
            rows="5"
            v-model="input.remarks"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="innerSection w-full flex py-6">
      <div class="container mx-auto p-4">
        <h3 class="text-4xl">{{ $t("message.about_you") }}</h3>
        <p class="mb-6">{{ $t("message.about_you_subtitle") }}</p>

        <div class="grid lg:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-6 gap-4">
          <div>
            {{ $t("message.first_time_visiting") }}
            <input
              type="checkbox"
              name="switch"
              v-model="input.firstTime"
              id="firstTime"
            />
            <label for="firstTime" class="mt-2"></label>
          </div>
          <div>
            <span class="required">{{ $t("message.ethnicity") }}</span>
            <input
              type="text"
              v-model="input.ethnicity"
              class="w-full border p-2"
            />
          </div>
          <div>
            {{ $t("message.religion_background") }}
            <input
              type="text"
              v-model="input.religionBackground"
              class="w-full border p-2"
            />
          </div>
          <div>
            {{ $t("message.origin_church") }}
            <input
              type="text"
              v-model="input.originChurch"
              class="w-full border p-2"
            />
          </div>
          <div>
            <span class="required">{{ $t("message.occupation") }}</span>
            <input
              type="text"
              v-model="input.occupation"
              class="w-full border p-2"
            />
          </div>
          <div>
            {{ $t("message.ipt") }}
            <input type="text" v-model="input.ipt" class="w-full border p-2" />
          </div>
          <div>
            <span class="required">{{ $t("message.visitation_purpose") }}</span>
            <v-select
              :options="purposeNeeds"
              :label="purposeLabel"
              class="bg-white"
              multiple
              :reduce="(purpose) => purpose.name"
              v-model="input.visitPurpose"
            ></v-select>
          </div>
          <div v-if="needSpecify">
            {{ $t("message.specify") }}
            <input
              type="text"
              v-model="input.visitSpecify"
              class="w-full border p-2"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container mx-auto p-4 mt-12">
      <div class="flex flex-wrap gap-5 lg:justify-start justify-center">
        <button
          @click="submitResponse"
          class="
            border
            shadow-md
            bg-green-700
            hover:bg-green-400
            text-white
            rounded-md
            p-2
            w-32
          "
        >
          Submit
        </button>
        <button
          @click="resetInput"
          class="
            border
            shadow-md
            bg-gray-700
            hover:bg-gray-400
            text-white
            rounded-md
            p-2
            w-32
          "
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueSelect from "vue-select";

export default {
  components: {
    "v-select": VueSelect,
  },
  name: "visitor",
  created() {
    this.getData();
  },
  data() {
    return {
      purposeNeeds: [],
      purposeLabel: "name",
      /**Drop downs */
      genderOptions: [
        { id: "m", name: "Male" },
        { id: "f", name: "Female" },
      ],
      input: {
        /**Input fields */
        requirePrayer: false,
        firstTime: false,
        fullName: "",
        gender: "",
        age: "",
        email: "",
        mobile: "",
        officeHome: "",
        language: "",
        remarks: "",
        /**Extra information */
        firstTime: false,
        ethnicity: "",
        religionBackground: "",
        originChurch: "",
        originChurch: "",
        visitPurpose: [],
        visitSpecify: "",
        occupation: "",
        ipt: "",
      },
    };
  },
  methods: {
    changeLang() {
      if (this.$i18n.locale == "en") {
        this.$i18n.locale = "bm";
      } else {
        this.$i18n.locale = "en";
      }
    },
    postResponse(token) {
      this.input["token"] = token;
      axios({
        url: `${process.env.VUE_APP_URL}/api/visitor/submit`,
        method: "POST",
        data: this.input,
      })
        .then((response) => {
          console.log(response.data);
          this.$swal({
            icon: "success",
            title: "Connect With Us",
            text: response.data.message,
          });
          this.resetInput();
        })
        .catch((error) => {
          if (error.hasOwnProperty("response")) {
            this.$swal({
              icon: "error",
              title: "Submit Response",
              text: error.response.data.message,
            });
          }
        });
    },
    submitResponse() {
      this.$recaptchaLoaded().then(() => {
        console.log("recaptcha loaded");
        this.$recaptcha("connect_with_us").then((token) => {
          this.postResponse(token);
        });
      });
    },
    resetInput() {
      for (const [key, value] of Object.entries(this.input)) {
        if (typeof this.input[key] === "object") {
          this.input[key] = [];
        } else {
          this.input[key] = "";
        }
      }
    },
    getData() {
      axios({
        url: `${process.env.VUE_APP_URL}/api/purpose-needs`,
        methods: "GET",
      })
        .then((response) => {
          this.purposeNeeds = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    currentLang: function () {
      if (this.currentLang == "bm") {
        this.purposeLabel = "description";
      } else {
        this.purposeLabel = "name";
      }
    },
  },
  computed: {
    currentLang: function () {
      return this.$i18n.locale;
    },
    langText: function () {
      if (this.$i18n.locale == "en") {
        return "Tukar Bahasa (BM)";
      }
      return "Change Language (Eng)";
    },
    needSpecify: function () {
      if (this.input.visitPurpose.includes("Others")) {
        return true;
      }
      return false;
    },
  },
};
</script>


<style scoped>
.innerSection {
  background: #f2f8f5;
}

.bg-service {
  background: url("~@/assets/images/visitor.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
